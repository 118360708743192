<template>
  <div class="about">
    <s-header />
    <n-bar :userType="'teacher'" :activeItemName="'AnaTeaching'" />
    <support />

    <div class="pageContent">
      <div class="in bg_main">
        <!-- 头部tab -->
        <div class="topBar bg_color">
          <div class="tabBtn">
            <div :class="isClass ? '' : 'current'" @click="caseData">
              学案数据分析
            </div>
            <div :class="isClass ? 'current' : ''" @click="classData">
              班级数据分析
            </div>
          </div>
          <div class="dateTab">
            <van-tabs v-model:active="activeDate" @change="changeDate">
              <van-tab title="今日"></van-tab>
              <van-tab title="本周"></van-tab>
              <van-tab title="本月"></van-tab>
              <van-tab title="本学年"></van-tab>
            </van-tabs>
          </div>
        </div>
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import sHeader from "@/components/SimpleHeader";
import nBar from "@/components/NavBar";
import support from "@/components/Support";

const router = useRouter();
const route = useRoute();

let tabIndex = route.query.date ? route.query.date : 0;

let activeDate = ref(tabIndex);
let isClass = ref(false);

const caseData = () => {
  isClass.value = false;
  router.push({
    path: "/teacher/AnaTeaching/caseDataAna",
    query: { date: route.query.date ? route.query.date : "" },
  });
};
const classData = () => {
  isClass.value = true;
  router.push({
    path: "/teacher/AnaTeaching/classDataAna",
    query: { date: route.query.date ? route.query.date : "" },
  });
};

const changeDate = (e) => {
  router.push({ path: route.path, query: { date: e } });
};
</script>

<style lang="less" scoped>
@import "@/common/style/adapting.less";
@import "@/common/style/pageContent.less";
@import "@/common/style/bg_color.less";

.bg_main {
  background-color: rgb(247, 244, 244);
}
.topBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  .tabBtn {
    display: flex;
    align-items: center;
    div {
      font-size: 10px;
      padding: 5px 8px;
      color: #18a4e0;
      border: 1px solid #18a4e0;
      border-radius: 3px;
    }
    div:nth-child(2) {
      margin-left: 10px;
    }
    .current {
      background-color: #18a4e0;
      color: #fff;
    }
  }
  .dateTab {
    width: 50%;
  }
  :deep(.van-tab__text) {
    font-size: 12px;
  }
}
</style>
